import React from 'react';
import './gateway.css';

const Gateway = () => {
  return (
    <div className='div'>
      
    </div>
  )
}

export default Gateway
